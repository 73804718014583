import React from 'react';
import {AnimatePresence} from 'framer-motion';

export const wrapPageElement = ({element}) => (
	<AnimatePresence exitBeforeEnter>{element}</AnimatePresence>
);

export const shouldUpdateScroll = ({
	routerProps: { location },
	getSavedScrollPosition,
  }) => {
	if (location.action === 'PUSH') {
	  window.setTimeout(() => window.scrollTo(0, 0), 300);
	} else {
	  const savedPosition = getSavedScrollPosition(location);
	  window.setTimeout(() => window.scrollTo(...(savedPosition || [0, 0])), 300);
	}
	return false;
  };